import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { NavigationWrapper } from './components';
import { SignIn } from './features/authentication/signin/components/SignIn';
import Dashboard from './features/dashboard/components/Dashboard';
import { RouteProtector } from './features/authentication/signin/components/RouteProtector';
import { NotFound } from './components/shared/notFound/NotFound';

const App = () => (
  <BrowserRouter basename="/">
    <Routes>
      <Route path="/" element={<Navigate replace to="/hub" />} />
      <Route path="/" element={<RouteProtector />}>
        <Route path="/" element={<NavigationWrapper />}>
          <Route path="/hub" element={<Dashboard />} />
        </Route>
      </Route>
      <Route path="/login" element={<SignIn />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default App;
