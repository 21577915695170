import { KEYCLOCK_DEFAULT_REALM } from '../constants';
export const groupBy = (key: string, list: any[]) =>
  list.reduce(
    (groups, item) => ({
      ...groups,
      [item[key]]: item,
    }),
    {}
  );


export const stringAvatar = (name: String) => ({
  sx: {
    border: 1,
    marginTop: '7px',
    marginBottom: '7px',
  },
  children: name,
});


export const isValidValue = (obj: any) =>
  obj !== null && obj !== undefined && obj !== '' && obj !== 'undefined';

export const areValidValues = (values: any) =>
  values.slice(0).reduce((_: any, currentValue: any, i: any, arr: any) => {
    const isValid = isValidValue(currentValue);
    if (!isValid) {
      arr.splice(1);
      return false;
    }
    return true;
  }, true);
export const dateToLocaleString = (value: string, dateOnly: boolean = false) =>
  (dateOnly
    ? new Date(value).toLocaleString('de-DE').split(',')[0]
    : new Date(value).toLocaleString('de-DE'));

export const convertStringToBool = (val: string) => {
  if (typeof val === 'boolean') return val;
  if (JSON.parse(val) === 0) return false;
  return true;
};

export const getAlphabeticEnvVarOrDefault = (envVar: string | undefined, defaultValue: string = ''): string =>
    (envVar && !envVar.startsWith('RA_') ? envVar : defaultValue);

// eslint-disable-next-line max-len
export const getBooleanEnvVarOrDefault = (envVar: string | undefined, defaultValue: boolean): boolean =>
  (envVar
  && (envVar.toLowerCase() === 'true' || envVar.toLowerCase() === 'false')
    ? JSON.parse(envVar.toLowerCase())
    : defaultValue);

export const getRealmForKeyClock = () => {
  const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get('realm')
  || getAlphabeticEnvVarOrDefault(
    process.env.REACT_APP_KEYCLOACK_REALM,
    KEYCLOCK_DEFAULT_REALM
  );
};


