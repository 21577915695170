import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import Cookies from 'universal-cookie';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import './config/lang/i18n';
import defaultTheme from './config/styles/defaultTheme';
import { store } from './app/store';
import App from './App';
import { getKeycloakInstance } from './keyclock';
import 'react-toastify/dist/ReactToastify.css';
import { getBooleanEnvVarOrDefault } from './shared/helpers';
import {  AUTH_TOKEN, KEYCLOCK_AUTH_DEFAULT  } from './shared/constants';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const keycloak = getKeycloakInstance();

const cookies = new Cookies();

const isKeyClockAuth = getBooleanEnvVarOrDefault(process.env.REACT_APP_KEYCLOCK_AUTH, KEYCLOCK_AUTH_DEFAULT);

const handleKeycloakAuth = (auth: any) => {
    const getAuthCookie = cookies.get(AUTH_TOKEN);
    if (auth && auth.token && (getAuthCookie === null || getAuthCookie === undefined)) {
        cookies.set(AUTH_TOKEN, auth.token);
    }
};


root.render(
    <ThemeProvider theme={defaultTheme}>
      <Provider store={store}>
          {isKeyClockAuth && keycloak !== null
              ? (
                  <ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: 'login-required', checkLoginIframe: false }} onTokens={(auth) => handleKeycloakAuth(auth)}>
                      <App />
                  </ReactKeycloakProvider>
              )
              : <App />}

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Provider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
