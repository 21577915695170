import { IApplicationConfig } from '../interface';

// eslint-disable-next-line import/no-named-default
import { ReactComponent as TaggerLogo } from '../../config/assets/icons/svgs/apps/Tagger.svg';
import { ReactComponent as ViewerLogo } from '../../config/assets/icons/svgs/apps/Viewer.svg';
import { ReactComponent as RobotLogo } from '../../config/assets/icons/svgs/apps/Robot.svg';
import { ReactComponent as CommanderLogo } from '../../config/assets/icons/svgs/apps/Commander.svg';
import { ReactComponent as APIsLogo } from '../../config/assets/icons/svgs/apps/API.svg';
import { ReactComponent as SDKLogo } from '../../config/assets/icons/svgs/apps/SDK.svg';
import { ReactComponent as DashboardrLogo } from '../../config/assets/icons/svgs/apps/Dashboard.svg';

export const APPLICATIONS = [
  { name: 'HUB_VIEWER', visible: true, enabled: true },
  { name: 'HUB_TAGGER', visible: true, enabled: false },
  { name: 'HUB_ROBOT', visible: true, enabled: true },
  { name: 'HUB_COMMANDER', visible: true, enabled: false },
  { name: 'HUB_KIBANA', visible: true, enabled: false },
  {
    name: 'HUB_API',
    visible: true,
    enabled: false,
  },
  {
    name: 'HUB_SDK',
    visible: true,
    enabled: false,
  },
// eslint-disable-next-line no-nested-ternary
].sort((a, b) => (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1));

export const APPLICATIONS_CONFIG: IApplicationConfig = {
  HUB_VIEWER: {
    icon: ViewerLogo,
    translate_key: 'viewer',
    link: '/viewer',
  },
  HUB_TAGGER: {
    icon: TaggerLogo,
    translate_key: 'tagger',
    link: '/tagger',
  },
  HUB_ROBOT: {
    icon: RobotLogo,
    translate_key: 'robot',
  },
  HUB_COMMANDER: {
    icon: CommanderLogo,
    translate_key: 'commander',
    link: '/backspin-backend/dashboard',
  },
  HUB_API: {
    icon: APIsLogo,
    translate_key: 'api',
  },
  HUB_SDK: {
    icon: SDKLogo,
    translate_key: 'sdk',
    docs: 'https://sdk.favendo.com/',
  },
  HUB_KIBANA: {
    icon: DashboardrLogo,
    translate_key: 'kibana',
    link: '/kibana',
  },
};
