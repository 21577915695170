export * from './http';
export * from './featuresName';
export * from './navigation';
export * from './routes';
export * from './dashbaord';

export const AUTH_TOKEN = 'AUTH_TOKEN';

export const KEYCLOCK_AUTH_DEFAULT = false;
export const KEYCLOCK_DEFAULT_REALM = 'favendo-office';
export const KEYCLOCK_DEFAULT_AUTH_URL = 'https://auth.fvndo.net/auth';
export const KEYCLOCK_DEFAULT_CLIENT_ID = 'zircon-web-client';
