import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useKeycloak } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { useEffect, useState } from 'react';
import { AUTH_TOKEN, KEYCLOCK_AUTH_DEFAULT  } from '../../../../shared/constants';
import {getBooleanEnvVarOrDefault} from "../../../../shared/helpers";
import {FvendoBackDrop} from "../../../../components/shared/backdrop";

let keycloak: Keycloak; let initialized: unknown;

export const RouteProtector = () => {
  const cookies = new Cookies();
  const sleep = (ms: number) => new Promise((resolve) => { setTimeout(resolve, ms); });
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  const isKeyClockAuth = getBooleanEnvVarOrDefault(process.env.REACT_APP_KEYCLOCK_AUTH, KEYCLOCK_AUTH_DEFAULT);

  if (isKeyClockAuth) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const keycloakContext = useKeycloak();
    keycloak = keycloakContext.keycloak;
    initialized = keycloakContext.initialized;
  }

  useEffect(() => {
    // this is keycloak auth checker & it will also add 1 second deplay after keycloak loggedin & redirected to app in order for AUTH_TOKEN cookie to set.
    const checkKeycloakAuth = async () => {
      await sleep(1000);
      const loggedIn = !!((cookies.get(AUTH_TOKEN) && cookies.get(AUTH_TOKEN) !== undefined) || keycloak.token !== undefined);
      setIsLoggedIn(loggedIn);
    };

    if (isKeyClockAuth) {
      checkKeycloakAuth();
    } else {
      setIsLoggedIn(!!(cookies.get(AUTH_TOKEN) && cookies.get(AUTH_TOKEN) !== undefined));
    }
  }, [keycloak, initialized]);

  if (isLoggedIn === null) {
    return (
        <FvendoBackDrop isShow />
    );
  }

  const returnLoginRoute = () => {
    if (isLoggedIn) {
      return <Outlet />;
    } if (keycloak) {
      return <Navigate to={`/hub?${new URLSearchParams(window.location.search).toString()}`} replace />;
    }
    return <Navigate to="/login" replace />;
  };
  return returnLoginRoute();
};