import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood,
  //    so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    const { payload } = action;
    if (payload && payload.status === 401) {
      window.history.replaceState(null, '', '/login');
    }
    if (payload && payload === 404) {
      toast.error('Something went wrong!');
    }
  }
  return next(action);
};
