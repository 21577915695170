/* eslint-disable */
import Keycloak from 'keycloak-js';
import {
  KEYCLOCK_AUTH_DEFAULT,
  KEYCLOCK_DEFAULT_AUTH_URL,
  KEYCLOCK_DEFAULT_CLIENT_ID,
} from './shared/constants';
import {
  getAlphabeticEnvVarOrDefault,
  getBooleanEnvVarOrDefault,
  getRealmForKeyClock,
} from './shared/helpers';

let keycloakInstance: Keycloak | null = null;

const isKeyClockAuth = getBooleanEnvVarOrDefault(
    process.env.REACT_APP_KEYCLOCK_AUTH,
    KEYCLOCK_AUTH_DEFAULT
);

const getKeycloakConfig = () => {
  if (isKeyClockAuth) {
    console.log('getKeyclokConfig process.env.REACT_APP_KEYCLOCK_CLIENT_ID, KEYCLOCK_DEFAULT_CLIENT_ID', process.env.REACT_APP_KEYCLOCK_CLIENT_ID, KEYCLOCK_DEFAULT_CLIENT_ID)
    console.log('getKeyclokConfig process.env.REACT_APP_KEYCLOCK_CLIENT_ID, KEYCLOCK_DEFAULT_CLIENT_ID', process.env.REACT_APP_KEYCLOCK_CLIENT_ID, KEYCLOCK_DEFAULT_CLIENT_ID)
    return {
      realm: getRealmForKeyClock(),
      url: getAlphabeticEnvVarOrDefault(process.env.REACT_APP_KEYCLOCK_AUTH_URL, KEYCLOCK_DEFAULT_AUTH_URL),
      clientId: getAlphabeticEnvVarOrDefault(process.env.REACT_APP_KEYCLOCK_CLIENT_ID, KEYCLOCK_DEFAULT_CLIENT_ID),
    };
  }
  return undefined;
};

console.log(' 33 url', getAlphabeticEnvVarOrDefault(process.env.REACT_APP_KEYCLOCK_AUTH_URL, KEYCLOCK_DEFAULT_AUTH_URL))
console.log(' 33 client', getAlphabeticEnvVarOrDefault(process.env.REACT_APP_KEYCLOCK_CLIENT_ID, KEYCLOCK_DEFAULT_CLIENT_ID))
console.log(' 33 auth',  getBooleanEnvVarOrDefault(
    process.env.REACT_APP_KEYCLOCK_AUTH,
    KEYCLOCK_AUTH_DEFAULT
))

export const getKeycloakInstance = () => {
  if (!keycloakInstance) {
    keycloakInstance = new Keycloak(getKeycloakConfig());
  }
  return keycloakInstance;
};